.button {
  background-color: rgb(145, 144, 144);
  margin-top: 30px;
  margin-right:10px;
  color: rgb(247, 236, 236) !important;
  text-align: left;
  font-size: 20px;
  width: 120px;
  height: 120px;

}
.button:hover {
  background-color: #3672f4;
  cursor: pointer;
}

.danger {
  background-color: #3672f4;
}

.success {
  background-color: #4caf50;
}

.buttontakeaway{
  background-color: #3672f4;
  margin-top: 30px;
  margin-right:10px;
  color: rgb(247, 236, 236) !important;
  text-align: left;
  font-size: 20px;
  width: 180px;
  height: 120px;
}
.buttontakeaway:hover {
  background-color: #3672f4;
  cursor: pointer;
}
.completed-color-class {
  background-color: green;
  color: white;
}
.green-color-class {
  background-color: green;
}
.orange-color-class {
  background-color: orange;
}

.blue-color-class {
  background-color: #3672f4;
}
.custom-card .ant-card-head {
  background: #13AAFF;
  color: black;
  text-align: left;
  border: 2px solid black;
  font-size: 30px;
}

.custom-card .ant-card-body {
  font-size: 30px;
  background: linear-gradient(to right, #e8eaeb, #ffffff);
  color: black;
  border: 2px solid black;
}
.custom-card .ant-descriptions-item-content{
  font-size: 20px;
  font-weight: bold;
}
.custom-button {
  font-size: 20px;
  color: black;
  background-color: #13AAFF;
  border: 2px solid black;
  padding: 10px 10px;
  width: 150px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 5px;
  margin-top: 15px;
}
.add-table-button {
  font-size: 20px;
  color: rgb(238, 238, 238);
  background-color: #3672f4;
  border: 2px solid rgb(0, 0, 0);
  padding: 10px 10px;
  width: 50%;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  margin-top: 15px;
}
.delete-table-button{
  font-size: 20px;
  color: rgb(236, 226, 226);
  background-color: #f70707;
  border: 2px solid black;
  padding: 10px 10px;
  width: 50%;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  margin-top: 15px;
}
.delete-table-button:hover {
  background-color: rgb(225, 225, 233);
  cursor: pointer;
}
@media screen and (max-width: 1500px) {
  .custom-button {
    font-size: 20px;
    color: black;
    background-color: #13AAFF;
    border: 2px solid black;
    padding: 5px 5px;
    width: 90px;
    height: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
}
.custom-button:hover {
  background-color: rgb(225, 225, 233);
  cursor: pointer;
}

.custom-card-total .ant-card-body {
  background: #13AAFF;
}