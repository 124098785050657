.edit-btn {
    background-color: #EEA414 !important;
    color: #fff !important;
    margin: 10px !important;
  }

  .cancel-btn {
    background-color: #CF4D8B !important;
    color: #fff !important;
    margin: 10px !important;
  }
  /* .custom-notification {
    background-color: #2196f3;
    color: #fff;
  } */